@import '@/styles/artifacts.scss';






















































































.consumption-comparison-panel {
  margin: auto;

  .label {
    margin-bottom: -1rem;
    font-size: 0.85rem;
  }

  .consumption {
    text-align: right;
  }

  .percentage-diff {
    margin: 7px auto;
    letter-spacing: 0.1rem;

    &.negative {
      color: colorVodafone(green);
    }

    &.positive {
      color: colorVodafone(vodafone-red);
    }
  }

  .no-data {
    font-size: 1.1rem;
  }
}
