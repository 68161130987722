@import '@/styles/artifacts.scss';





































































































































































































































































.header {
  color: colorVodafone(black);
  font-size: fontSize(fs-150);
  font-weight: fontWeight(regular);
  padding-top: 0;
  padding-bottom: 0;
}
.subheader {
  color: colorVodafone(black);
  font-size: fontSize(fs-base);
  padding-top: 0;
  padding-bottom: 0;
}
.separator {
  margin-right: 20px;
}
