@import '@/styles/artifacts.scss';










































.domain-ui-gateway-health {
  &.capitalize {
    text-transform: capitalize;
  }
  &.obsolete {
    text-decoration: line-through;
  }
  &.inline {
    display: inline;
  }
  &.UNKNOWN {
    color: colorVodafone(health-unknown);
  }
  &.CRITICAL {
    color: colorVodafone(health-critical);
  }
  &.DEGRADED {
    color: colorVodafone(health-degraded);
  }
  &.OK {
    color: colorVodafone(health-ok);
  }
}
