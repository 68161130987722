@import '@/styles/artifacts.scss';


















.domain-ui-gateway-icon.UNKNOWN .icon-stroke {
  stroke: colorVodafone(grey);
}
.domain-ui-gateway-icon.CRITICAL .icon-stroke {
  stroke: colorVodafone(vodafone-red);
}
.domain-ui-gateway-icon.DEGRADED .icon-stroke {
  stroke: colorVodafone(fresh-orange);
}
.domain-ui-gateway-icon.OK .icon-stroke {
  stroke: colorVodafone(digital-green);
}
