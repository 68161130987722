@import '@/styles/artifacts.scss';


























.domain-ui-attribute-configuration-type.inline {
  display: inline;
}
