@import '@/styles/artifacts.scss';

































.first-link {
  margin-right: 8px;
}
