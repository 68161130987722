@import '@/styles/artifacts.scss';

















































.vertical-alignment {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.image-container {
  width: 170px;
  height: 170px;
}
.gauge-subtitle {
  color: colorVodafone(health-critical);
  font-size: fontSize(fs-50);
}
