@import '@/styles/artifacts.scss';


























































.container {
  width: 100%;
}
.warning {
  color: colorVodafone(vodafone-red);
}
.space {
  padding-left: 8px;
  padding-right: 8px;
}
.alarm-status {
  display: flex;
  align-items: center;
  p {
    margin: 0 0 0 12px;
  }
}
.message {
  margin-bottom: 8px;
}
