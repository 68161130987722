@import '@/styles/artifacts.scss';






















































.tree-node-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.text-light {
  color: colorVodafone(grey);
}
.text-overflow {
  @include textOverflow();
}
