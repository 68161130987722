@import '@/styles/artifacts.scss';


























































.domain-ui-metric-value.inline {
  display: inline;
}
