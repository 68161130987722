@import '@/styles/artifacts.scss';


































































.title {
  font-size: fontSize(fs-150);
}
.health {
  font-size: fontSize(fs-50);
}
.total {
  font-size: fontSize(fs-300);
  line-height: lineHeight(lh-reset);
  color: colorVodafone(dark-grey);
  text-align: right;
}
