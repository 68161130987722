@import '@/styles/artifacts.scss';
































.domain-ui-heating-system-info {
  display: contents;
}
.text-light {
  color: colorVodafone(grey);
}
.text-overflow {
  @include textOverflow();
}
