@import '@/styles/artifacts.scss';






















































































































.container {
  width: 100%;
}
.warning {
  color: colorVodafone(vodafone-red);
}
.space {
  padding-left: 8px;
  padding-right: 8px;
}
