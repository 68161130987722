@import '@/styles/artifacts.scss';





















































.dhw-temperature-widget {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
  background: center no-repeat white;
  color: colorVodafone(dark-grey);
  justify-content: center;
  align-content: center;
  padding: 15px;

  .widget-label {
    font-size: fontSize(fs-150);
    display: flex;
    position: relative;
    top: -10px;
    justify-content: space-around;
    flex-direction: column;

    .center {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .widget-content {
    border-radius: 50%;
    padding: 30px;
    border: 7px solid colorVodafone(light-grey);
    min-height: 110px;
    min-width: 110px;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    margin: auto;
  }

  .ui-panel-separator {
    margin-top: 0;
    margin-bottom: 0;
  }

  .temp-container {
    margin-top: 5px;
    margin-bottom: 7px;

    .label {
      font-size: fontSize(fs-100);
      margin-right: 10px;
    }

    .temp-value {
      position: relative;
      top: 2px;
      color: colorVodafone(vodafone-red);
      font-size: fontSize(fs-300);
      line-height: fontSize(fs-150);
      font-weight: fontWeight(regular);
    }

    .celsius {
      margin-left: 2px;
    }
  }
}
