@import '@/styles/artifacts.scss';














































































.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.separator {
  margin-top: 2.5rem;
}
