@mixin widget-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: auto;
  height: 100%;
  padding: 10px 20px;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.13);
  background: white;
  color: colorVodafone(black);
}
