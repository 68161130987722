@import '@/styles/artifacts.scss';















































































































.header {
  padding-bottom: 0;
}
.content {
  max-height: 275px;
  overflow-y: scroll;
}
.message {
  display: grid;
  grid-template-columns: 3fr 1fr;
  border-bottom: 1px solid #f4f4f4;
  margin-bottom: 4px;
  .info {
    h4 {
      margin: 0;
    }
    p {
      color: #888;
      margin: 0 0 6px;
    }
  }
  .view-more {
    display: flex;
    justify-content: end;
    align-items: center;
    a {
      background-color: rgb(230, 0, 0);
      color: white;
      border: none;
      padding: 4px 8px;
      border-radius: 2px;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      &:hover {
        background-color: rgba(230, 0, 0, 0.8);
      }
    }
  }
}
