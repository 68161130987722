@import '@/styles/artifacts.scss';


























.domain-ui-vendor-api-type.inline {
  display: inline;
}
