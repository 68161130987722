@import '@/styles/artifacts.scss';

































.consumption-widget-info-modal-wrapper {
  padding: 20px;
  margin-top: 5px;

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    outline: none;
    border: none;
    padding: 0;
    background: transparent;
  }

  .no-data {
    margin: 12px auto auto;
  }

  .consumption-widget-info-modal-wrapper-content {
    margin-top: 5px;
  }
}
