@import '@/styles/artifacts.scss';



















































































@import '../assets/scss/widget-wrapper-mixin.scss';
$iconSize: 32;

.consumption-widget {
  &:not(.custom-wrapper) {
    @include widget-wrapper;
  }

  &.custom-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 0;
  }

  .icon {
    flex: 0 0 auto;
    width: #{$iconSize}px;
    height: #{$iconSize}px;
    margin-top: 2rem;
    margin-bottom: 10px;
  }

  .label,
  .value,
  .unit {
    margin: 3px auto;
  }

  .unit {
    font-weight: 500;
  }

  .button {
    border-radius: 15px;
    background: colorVodafone(black);
    color: colorVodafone(white);
    padding: 3px 16px;
    text-decoration: none !important;
  }

  .consumption-comparison-container {
    margin-top: 1.5rem;
    width: 100%;
  }

  .info-icon {
    position: absolute;
    right: 10px;
    width: 18px;
  }

  .center {
    text-align: center;
  }

  .buttons-container {
    width: 100%;
    padding-bottom: 1rem;
  }
}
