@import '@/styles/artifacts.scss';












































.vertical-alignment {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.image-container {
  width: 150px;
  height: 150px;
}
