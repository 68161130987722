@import '@/styles/artifacts.scss';





































































































































































































































































































































.gateway-icon {
  width: 100%;
  height: 100%;
}
.block {
  display: block;
}
.obsolete {
  text-decoration: line-through;
}
.text-light {
  color: colorVodafone(grey);
}
.text-overflow {
  @include textOverflow();
}
