@import '@/styles/artifacts.scss';




































.domain-ui-spot-role-aggregation-widget {
  --inner-stop-color: #ccc1;
  --inner-ring-color: #ccc2;
  --outer-stop-color: #cccccc08;
  --outer-ring-color: #cccccc18;

  position: relative;
  display: flex;
  flex-direction: column;
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  user-select: none;
  background: radial-gradient(
      circle,
      #0000 50px,
      var(--inner-stop-color) 68px,
      var(--inner-ring-color) 68px,
      var(--inner-ring-color) 70px,
      #0000 70px,
      var(--outer-stop-color) 108px,
      var(--outer-ring-color) 108px,
      var(--outer-ring-color) 110px,
      #0000 110px
    )
    center no-repeat;
}
.image {
  max-width: 80%;
  max-height: 60%;
}

.label {
  flex: 0 0 auto;
  padding: 10px 15px 0;
  font-size: fontSize(fs-150);
  text-align: center;
  @include textOverflow();
}
.count {
  flex: 0 0 auto;
  padding: 0 15px;
  font-size: fontSize(fs-50);
  text-align: center;
  @include textOverflow();
}
.alerts {
  flex: 0 0 auto;
  padding: 0 15px;
  font-size: fontSize(fs-50);
  text-align: center;
  color: colorVodafone(health-critical);
  @include textOverflow();
}
.content {
  flex: 1 0 0;
  padding: 0 15px;
}
.footer {
  flex: 0 0 auto;
  padding: 0 15px;
}
