@import '@/styles/artifacts.scss';






























.icon-gateway.UNKNOWN .icon-stroke {
  stroke: colorVodafone(grey);
}
.icon-gateway.CRITICAL .icon-stroke {
  stroke: colorVodafone(vodafone-red);
}
.icon-gateway.DEGRADED .icon-stroke {
  stroke: colorVodafone(fresh-orange);
}
.icon-gateway.OK .icon-stroke {
  stroke: colorVodafone(digital-green);
}
