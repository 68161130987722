@import '@/styles/artifacts.scss';








































.domain-ui-attribute {
  display: contents;
}
