@import '@/styles/artifacts.scss';





























@import '../assets/scss/widget-wrapper-mixin.scss';
.current-month-previous-year-consumption-widget {
  @include widget-wrapper;

  .consumption-comparison-container {
    width: 100%;
  }
}
