<template lang="pug">
  .domain-ui-tree-node-name
    slot(v-if="treeNode !== undefined" v-bind="{ treeNode, label }")
      | {{ label }}
    slot(v-else name="empty")
      | –
</template>

<script lang="ts">
import { ObjectProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { floorShortLabel } from '../property-floor/label';
import { DomainUiTreeNodeNameTreeNodeFragment } from './__generated__/DomainUiTreeNodeNameTreeNodeFragment';

@Component
export default class TreeNodeName extends Vue {
  @ObjectProp()
  private readonly treeNode?: DomainUiTreeNodeNameTreeNodeFragment;

  private get label(): string {
    return this.treeNode === undefined
      ? ''
      : this.treeNode.__typename === 'PropertySubdivision'
      ? `${this.treeNode.name} (${floorShortLabel(this.treeNode.floor)})`
      : this.treeNode.name;
  }
}
</script>

<style scoped>
.domain-ui-tree-node-name {
  display: contents;
}
</style>
