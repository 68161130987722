@import '@/styles/artifacts.scss';























































































































.ongoing {
  color: colorVodafone(vodafone-red);
}
.text-light {
  color: colorVodafone(grey);
}
.text-overflow {
  @include textOverflow();
}
