@import '@/styles/artifacts.scss';





























































.title {
  font-weight: fontWeight(regular-bold);
  font-size: fontSize(fs-100);
}
.box {
  color: colorVodafone(grey);
}
