@import '@/styles/artifacts.scss';









































.domain-ui-spot-info {
  display: contents;
}
.text-light {
  color: colorVodafone(grey);
}
.text-overflow {
  @include textOverflow();
}
