@import '@/styles/artifacts.scss';






























































.health {
  font-size: fontSize(fs-50);
}
.total {
  font-size: fontSize(fs-300);
  line-height: lineHeight(lh-reset);
  color: colorVodafone(dark-grey);
  text-align: right;
}
