@import '@/styles/artifacts.scss';










































.domain-ui-gateway-info {
  display: contents;
}
.gateway-icon {
  width: 100%;
  height: 100%;
}
.serial {
  text-transform: uppercase;
}
.text-light {
  color: colorVodafone(grey);
}
.text-overflow {
  @include textOverflow();
}
